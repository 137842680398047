var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { nav: "", dense: "" } },
    [
      _c(
        "v-list-item",
        [
          _c("v-list-item-content", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column",
                staticStyle: { width: "100%" }
              },
              [
                _c("span", { staticClass: "caption text-uppercase" }, [
                  _vm._v("manage space")
                ]),
                _c("v-divider", {
                  staticClass: "mt-1",
                  attrs: { color: "grey" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-list-item",
        {
          attrs: {
            to: {
              name: "space-user-management",
              params: {
                oid: _vm.$route.params.oid,
                sid: _vm.$route.params.sid,
                iid: _vm.$route.params.iid,
                snid: _vm.$route.params.snid
              }
            }
          }
        },
        [
          _c("v-list-item-title", [
            _c(
              "div",
              { staticClass: "subtitle-2 d-flex align-center" },
              [
                _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                  _vm._v("mdi-account-group-outline")
                ]),
                _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE
                  ? _c("span", { staticClass: "mr-2" }, [
                      _vm._v("Course Users")
                    ])
                  : _vm.currentSpaceType === _vm.spaceTypes.RESEARCH_SPACE
                  ? _c("span", { staticClass: "mr-2" }, [
                      _vm._v("Project Users")
                    ])
                  : _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
                  ? _c("span", { staticClass: "mr-2" }, [
                      _vm._v("Dataset Users")
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.currentSpaceType !== _vm.spaceTypes.VENDOR_SPACE ||
      _vm.userInfo.is_sysadmin === true
        ? _c(
            "v-list-item",
            {
              attrs: {
                to: {
                  name: "space-delete",
                  params: {
                    oid: _vm.$route.params.oid,
                    sid: _vm.$route.params.sid,
                    iid: _vm.$route.params.iid,
                    snid: _vm.$route.params.snid
                  }
                }
              }
            },
            [
              _c("v-list-item-title", [
                _c(
                  "div",
                  { staticClass: "subtitle-2 d-flex align-center" },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-1", attrs: { small: "" } },
                      [_vm._v("delete")]
                    ),
                    _vm._v(" Delete space ")
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }